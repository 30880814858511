import * as React from "react"
import styled from "styled-components"
import { Page } from "../shared/Page/Page"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FlatButton } from "../shared/Ui/FlatButton/FlatButton"
import media from "css-in-js-media"

const Container = styled.div`
    section.hero {
        display: flex;
        align-items: center;
        padding: 100rem 0 120rem;
        gap: 10rem;

        ${media("<largeDesktop")} {
            div.left {
                flex: 1 10%;
                padding-right: 30rem;

                h1 {
                    font-size: 55rem;
                }

                h2 {
                    font-size: 19rem;
                    line-height: 35rem;
                }
            }

            div.right {
                flex: 1;
                padding: 0;

                .photo {
                    height: 300rem;
                    max-width: 90%;
                }

                .back {
                    display: none;
                }
            }
        }

        ${media("<desktop")} {
            flex-direction: column-reverse;
            padding: 10rem 0 100rem;
            gap: 40rem;

            div.left {
                text-align: center;
                max-width: 600rem;
            }

            div.right {
                width: 100%;

                .photo {
                    max-width: 100%;
                }
            }
        }

        .left {
            flex: 1;

            h1 {
                font-size: 65rem;
                font-family: "Gilroy-Bold", sans-serif;
                color: ${({ theme }) => theme.text.dark};
                font-weight: unset;
                margin: 0;
            }

            h2 {
                font-weight: 400;
                font-size: 20rem;
                color: ${({ theme }) => theme.text.lighter};
                line-height: 33rem;
            }
        }

        .right {
            flex: 1 33%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 0 30rem 0 80rem;

            .photo {
                flex: 1;
                border-radius: 10rem;
                overflow: hidden;
                box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
                max-width: 75%;
                position: relative;
                z-index: 50;
            }

            .back {
                position: absolute;
                width: 65%;
                height: 300rem;
                background: #eff6ff;
                border-radius: 10rem;
                top: 0;
                left: 0;
                transform: translateX(250rem) translateY(145rem);
            }
        }
    }

    section.steps {
        display: flex;
        flex-direction: column;
        padding: 100rem 50rem;
        justify-content: center;
        text-align: center;

        .small {
            color: ${({ theme }) => theme.text.light};
            text-transform: uppercase;
            font-size: 14rem;
            font-weight: 600;
        }

        h3 {
            font-family: "Gilroy-SemiBold", sans-serif;
            color: ${({ theme }) => theme.text.dark};
            font-size: 28rem;
            max-width: 500rem;
            margin: 20rem auto;
        }

        .step-container {
            display: flex;
            padding: 60rem 0;

            ${media("<desktop")} {
                flex-direction: column;
                gap: 60rem;
            }

            .step {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 25rem;
                    background: #eff6ff;
                    border-radius: 90%;
                    width: 80rem;
                    height: 80rem;
                    color: ${({ theme }) => theme.accents.light};
                    text-align: center;
                    font-family: "Gilroy-Bold", sans-serif;
                    margin-bottom: 30rem;
                }

                .text {
                    color: ${({ theme }) => theme.text.light};
                    max-width: 260rem;
                    margin: auto;
                    font-size: 18rem;
                    line-height: 30rem;
                }
            }
        }
    }
`

const HowItWorks = ({ location }) => {
    return (
        <Page constrained location={location}>
            <Container>
                <section className={"hero"}>
                    <div className={"left"}>
                        <h1>How 23shout works</h1>
                        <h2>
                            We're on a mission to build a contact centre that
                            makes calling frictionless and fun; that means
                            redefining age old technology to fit a modern world
                            that demands more.
                        </h2>
                        <Link to={`/get-started`}>
                            <FlatButton color={"primary"} size={"larger"}>
                                Get started <Chevron />
                            </FlatButton>
                        </Link>
                    </div>

                    <div className={"right"}>
                        <StaticImage
                            src={"../images/how-it-works/hero-pic.jpg"}
                            alt={"Office with team using 23shout"}
                            className={"photo"}
                            objectFit={"cover"}
                            layout={"fullWidth"}
                            placeholder={"none"}
                            quality={100}
                        />

                        <div className={"back"} />
                    </div>
                </section>

                <section className={"steps"}>
                    <div className={"small"}>In 3 easy steps</div>
                    <h3>
                        Call management and omnichannel messaging for any
                        business
                    </h3>
                    <div className={"step-container"}>
                        <div className={"step"}>
                            <div className={"number"}>1</div>
                            <div className={"text"}>
                                Create your personalised team using our centre
                                builder
                            </div>
                        </div>

                        <div className={"step"}>
                            <div className={"number"}>2</div>
                            <div className={"text"}>
                                Link your numbers and social media accounts
                            </div>
                        </div>

                        <div className={"step"}>
                            <div className={"number"}>3</div>
                            <div className={"text"}>
                                Invite your team and 23shout will handle the
                                rest
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </Page>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}

export default HowItWorks
